import React from "react";
import Base from "../components/Base";

const contact = () => {
    return (
            <Base title={'Contact Us'} description={"See how to find us and contact us for a quote! We are based in Harrow but operate in surrounding areas such as Edgeware, Watford, Uxbridg, Standmore and Central London."}>
                <h2 className={'colouredTitle'}>Contact Details</h2>
                <hr/>
                <div id={'contactPageDetails'}>
                    <p id={'mobile'}><b>Mobile:</b> 07771367237</p>
                    <p id={'email'}><b>Email:</b> enquiries@jackelectrician.co.uk</p>
                    <p><b>Operating areas:</b></p>
                    <ul>
                        <li>Harrow</li>
                        <li>Edgeware</li>
                        <li>Watford</li>
                        <li>Uxbridge</li>
                        <li>Central London</li>
                        <li>St Albans</li>
                        <li>Stanmore</li>
                        <li>Pinner</li>
                        <li>Wembley</li>
                        <li>Barnet</li>
                    </ul>
                </div>

                <iframe 
                    title="Location embed"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46437.89385230992!2d-0.36713948659332024!3d51.60030126181697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876151b86091531%3A0x2e45f04d26083813!2sJack%20O&#39;Connor%20Electrician!5e0!3m2!1sen!2suk!4v1582054474251!5m2!1sen!2suk" 
                    width="600" 
                    height="450" 
                    frameborder="0" 
                    style={{border:0, width: '100%'}}
                    allowfullscreen="">
                </iframe>
            </Base>
        );
}
export default contact;